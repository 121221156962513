<template>
  <layout-sub name="standard">
    <b-row class="p-standard-row mx-0">
      <b-col
        cols="12"
        class="p-standard-col p-standard-effect px-0 d-block d-md-none"
      >
        <base-image
          :src="require('@/assets/img-standard-1.jpg')"
          class="p-standard-img"
        />
      </b-col>
      <b-col cols="12" md="6" class="p-standard-col px-0">
        <base-image
          :src="require('@/assets/img-standard-1-txt.jpg')"
          class="p-standard-img p-standard-txt"
        />
      </b-col>
      <b-col
        md="6"
        class="p-standard-col p-standard-effect px-0 d-none d-md-block"
      >
        <base-image
          :src="require('@/assets/img-standard-1.jpg')"
          class="p-standard-img"
        />
      </b-col>
    </b-row>
    <b-row class="p-standard-row mt-3 mt-md-0 mx-0">
      <b-col cols="12" md="6" class="p-standard-col p-standard-effect px-0">
        <base-image
          :src="require('@/assets/img-standard-2.jpg')"
          class="p-standard-img"
        />
      </b-col>
      <b-col cols="12" md="6" class="p-standard-col px-0">
        <base-image
          :src="require('@/assets/img-standard-2-txt.jpg')"
          class="p-standard-img p-standard-txt"
        />
      </b-col>
    </b-row>
    <b-row class="p-standard-row mt-3 mt-md-0 mx-0">
      <b-col
        cols="12"
        class="p-standard-col p-standard-effect px-0 d-block d-md-none"
      >
        <base-image
          :src="require('@/assets/img-standard-3.jpg')"
          class="p-standard-img"
        />
      </b-col>
      <b-col cols="12" md="6" class="p-standard-col px-0">
        <base-image
          :src="require('@/assets/img-standard-3-txt.jpg')"
          class="p-standard-img p-standard-txt"
        />
      </b-col>
      <b-col
        md="6"
        class="p-standard-col p-standard-effect px-0 d-none d-md-block"
      >
        <base-image
          :src="require('@/assets/img-standard-3.jpg')"
          class="p-standard-img"
        />
      </b-col>
    </b-row>
    <b-row class="p-standard-row mt-3 mt-md-0 mx-0">
      <b-col cols="12" md="6" class="p-standard-col p-standard-effect px-0">
        <base-image
          :src="require('@/assets/img-standard-4.jpg')"
          class="p-standard-img"
        />
      </b-col>
      <b-col cols="12" md="6" class="p-standard-col px-0">
        <base-image
          :src="require('@/assets/img-standard-4-txt.jpg')"
          class="p-standard-img p-standard-txt"
        />
      </b-col>
    </b-row>
  </layout-sub>
</template>

<script>
import LayoutSub from "@/components/LayoutSub";
import BaseImage from "@/components/BaseImage";

export default {
  name: "Standard",
  components: { LayoutSub, BaseImage },
};
</script>

<style lang="scss" scoped>
.p-standard {
  &-row {
    &:hover {
      .p-standard-effect .p-standard-img {
        transform: scale(1.1);
      }
    }
  }

  &-col {
    height: 200px;
    overflow: hidden;
  }

  &-img {
    width: 100%;
    height: 100%;
  }

  &-effect {
    .p-standard-img {
      transition: transform 0.3s;
    }
  }

  @include media-breakpoint-up(sm) {
    &-col {
      height: 250px;
    }
  }

  @include media-breakpoint-up(md) {
    &-col {
      height: 200px;
    }
  }

  @include media-breakpoint-up(xl) {
    &-col {
      height: 357px;
    }

    &-txt {
      background-position: center bottom;
      background-size: 100% auto;
      background-color: #f6ca13;
    }
  }
}
</style>
