<template>
  <div class="BaseImage" :style="{ backgroundImage: `url(${src})` }" />
</template>

<script>
export default {
  name: "BaseImage",
  props: {
    src: { type: String, default: "" },
  },
};
</script>

<style lang="scss" scoped>
.BaseImage {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
